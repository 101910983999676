import { filesReducer } from 'model/slices/filesSlice';
import { modelSelectionReducer } from 'model/slices/modelSelectionSlice';
import { outputStyleReducer } from 'model/slices/outputStyleSlice';
import { settingsReducer } from 'model/slices/settings';

import { promptDialogReducer } from '../slices/aiPromptLib';
import { appReducer } from '../slices/appSlice';
import { conversationsReducer } from '../slices/conversationsSlice';
import { cookieConsentReducer } from '../slices/cookiesSlice';
import { dataPrivacyReducer } from '../slices/dataPrivacySlice';
import { globalUploadReducer } from '../slices/globalUpload';
import { rateLimitReducer } from '../slices/rateLimit';

const reducer = {
  appReducer,
  rateLimitReducer,
  promptDialogReducer,
  cookieConsentReducer,
  modelSelectionReducer,
  uploadedFilesReducer: filesReducer,
  conversationsReducer,
  globalUploadReducer,
  outputStyleReducer,
  settingsReducer,
  dataPrivacyReducer,
};

export default reducer;
