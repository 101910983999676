import { PageLoader } from 'components';
import React, { Suspense, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { appActions } from '../model/slices/appSlice';
import { TStore } from '../store';
import { RootRoutesProps, routeConfig, RoutePath } from './router-provider';
import { RequireAuth } from './router-provider/requireAuth';

const Root = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inited = useSelector((state: TStore) => state.appReducer._inited);
  // eslint-disable-next-line
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(appActions.initAuthData());
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/') {
      navigate(RoutePath.main);
    }
  }, [navigate]);

  const renderWithWrapper = useCallback((route: RootRoutesProps) => {
    const element = (
      <Suspense fallback={<PageLoader />}>
        <div>{route.element}</div>
      </Suspense>
    );
    return (
      <Route
        key={route.path}
        path={route.path}
        element={route.authOnly ? <RequireAuth>{element}</RequireAuth> : element}
      />
    );
  }, []);

  return (
    <Suspense fallback={<PageLoader />}>
      {inited && <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>}
    </Suspense>
  );
};

export default Root;
