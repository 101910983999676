import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { TStore } from 'store';

import { RoutePath } from './index';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useSelector((state: TStore) => state.appReducer.auth);
  const token = sessionStorage.getItem('token');
  const location = useLocation();

  if (!auth || token === null) {
    return <Navigate to={RoutePath.login} state={{ from: location }} replace />;
  }
  return children;
};
