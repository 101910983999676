import { Dispatch } from '@reduxjs/toolkit';
import { catchErrorUtils } from 'utils/catchErrorUtils';

import { doRequest, get } from '../../../domain/api';
import RequestError from '../../../domain/exceptions/requestError';
import { VersionInfo } from './types';

export const fetchAppVersion = async (dispatch: Dispatch): Promise<VersionInfo | undefined> => {
  try {
    return await doRequest<VersionInfo>(
      get(dispatch, 'magenta_gateway/version'),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};
