import { Dispatch } from '@reduxjs/toolkit';
import { countUpperCaseLowerCaseDigits } from 'utils';
import { handleLogout } from 'utils/chatUtils';

import Auth, { RawUser } from './auth';
import RequestError from './exceptions/requestError';

export const REACT_APP_SMARTCHAT_API_HOST =
  process.env.REACT_APP_SMARTCHAT_API_HOST ?? 'smartchat-dev.ai-health.aisf.t-systems.net';
export const REACT_APP_PROTOCOL = 'https';

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
type Params = Record<string, string>;

export type StringResponse = {
  status: 'string';
  body: string;
};

export type ErrorResponse = {
  status: 'error';
  message: string;
  detail: unknown;
  error_description?: string;
  statusCode?: number;
};

export type UnauthorizedResponse = {
  status: 'unauthorized';
  message?: string;
};

export type ForbiddenResponse = {
  status: 'forbidden';
  message?: string;
};

export type OKResponse<T = unknown> = {
  status: 'ok';
  body?: T & { otp_needed?: string };
};

export type StreamResponse = {
  status: 'ok';
  body?: ReadableStream<Uint8Array> & { otp_needed?: string };
};

export type LoginResponseBody = {
  access_token: string;
  refresh_token: string;
};

export type ResponseBody<T = unknown> =
  | OKResponse<T>
  | ForbiddenResponse
  | UnauthorizedResponse
  | ErrorResponse
  | StringResponse
  | StreamResponse;

type BodyType = undefined | Omit<unknown, ''> | BodyInit;

type RequestFunction<T> = (url: string, params?: Params, body?: BodyType) => Promise<ResponseBody<T>>; // eslint-disable-line

type ArgsBase<T> = [Method, Dispatch, ...Parameters<RequestFunction<T>>, boolean | undefined];

const PROTOCOL = process.env.REACT_APP_PROTOCOL || REACT_APP_PROTOCOL;
const BASE_PROTOCOL = REACT_APP_SMARTCHAT_API_HOST.includes('localhost') ? 'http' : PROTOCOL;
const BASE = BASE_PROTOCOL + '://' + REACT_APP_SMARTCHAT_API_HOST;

const getToken = () => {
  return sessionStorage.getItem('token') || '';
};
const getRefreshToken = () => {
  return sessionStorage.getItem('refreshToken') ?? '';
};
// const getSessionState = () => {
//   return sessionStorage.getItem('sessionState') ?? '';
// };

const setTokens = (token: string, refreshToken: string, session_state: string = '') => {
  const counts = countUpperCaseLowerCaseDigits(token || refreshToken);
  if (counts.uppercase > 20 || counts.lowercase > 20 || counts.digits > 36) {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('refreshToken', refreshToken);
    sessionStorage.setItem('sessionState', session_state);
  } else {
    console.error('Token Value is not secured');
  }
};

const clearToken = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('refreshToken');
  sessionStorage.removeItem('sessionState');
};

export const clearAndLogout = (dispatch: Dispatch) => {
  clearToken();
  window.dispatchEvent(new Event('logout'));
  handleLogout(dispatch);
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.clearAndLogout = clearAndLogout;

const isBodyInit = (body: BodyType) =>
  [Blob, ArrayBuffer, FormData, URLSearchParams, ReadableStream].some(T => body instanceof T);

const stringify = (body: BodyType): BodyInit => {
  if (body && !isBodyInit(body) && typeof body !== 'string') {
    return JSON.stringify(body);
  } else {
    return body as BodyInit;
  }
};

const isURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

const request = async <T>(...args: ArgsBase<T>): Promise<ResponseBody<T>> => {
  try {
    const [method, dispatch, url, params = {}, _body, requiresToken = true] = args;

    const token = getToken();
    console.log(url, token.length === 0 || !requiresToken);

    if (token.length !== 0 || !requiresToken) {
      const parameters = new URLSearchParams(params).toString();
      const isFormData = _body instanceof FormData;
      const body = isFormData ? _body : stringify(_body);
      const headers: HeadersInit = requiresToken
        ? {
            token: token,
          }
        : {};
      if (!isFormData) {
        headers['content-type'] = 'application/json';
      }

      const base = isURL(url) ? url : `${BASE}/${url}`;

      const response = await fetch(`${base}${parameters ? '?' + parameters : ''}`, {
        method,
        headers,
        body,
      });
      const textResponse = response.clone();
      // TODO: find better condition
      if (base.includes('&model_selection')) {
        if (response.body) {
          return {
            status: 'ok',
            body: response.body as ReadableStream<Uint8Array>,
          };
        } else {
          return {
            status: 'error',
            message: 'no response body present',
            detail: response.body,
          };
        }
      } else {
        const status = response?.status;
        let responseBody: Record<string, unknown> | string;

        try {
          responseBody = await response.json();
        } catch (e) {
          responseBody = await textResponse.text();
        }
        switch (status) {
          case 401:
            return requestRefreshToken(() => request(...args), dispatch);
          case 403:
            return { status: 'forbidden' };
          default: {
            if (status >= 200 && status < 300) {
              if (typeof responseBody === 'string') {
                return {
                  status: 'string',
                  body: responseBody,
                };
              } else {
                return {
                  status: 'ok',
                  body: responseBody as T & { otp_needed?: string | undefined },
                };
              }
            } else {
              if (typeof responseBody === 'string') {
                return {
                  status: 'error',
                  message: response.statusText,
                  statusCode: status,
                  detail: responseBody,
                };
              } else {
                return {
                  status: 'error',
                  message: response.statusText,
                  statusCode: status,
                  detail: responseBody.detail,
                };
              }
            }
          }
        }
      }
    } else {
      clearAndLogout(dispatch);
      return {
        status: 'unauthorized',
        message: 'No Valid token',
      };
    }
  } catch (e) {
    const error: Error = e as Error;
    return {
      status: 'error',
      message: error.message,
      detail: error,
    };
  }
};

interface AuthToken {
  access_token: string;
  refresh_token: string;
  session_state: string;
}

const login = async (dispatch: Dispatch, username: string, pwd: string, otpField?: string) => {
  if (username === 'otp_user') {
    console.log(otpField);
  }
  try {
    const data = await doRequest<AuthToken>(
      post(dispatch, `magenta_gateway/iam/accessToken`, {}, { username, password: pwd }, false),
      (message, detail, statusCode) => new RequestError('read', message, detail, statusCode),
    );
    if (!data) {
      throw new Error('Authentication failed. Response is undefined.');
    }
    setTokens(data.access_token, data.refresh_token, data.session_state);
    return { ...data, status: 'ok' };
  } catch (error) {
    console.error('Error during authentication:', error);
    return { status: 'unauthorized' };
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
const requestRefreshToken = async (callback: Function, dispatch: Dispatch) => {
  const _refreshToken = getRefreshToken();
  if (_refreshToken) {
    try {
      const data = await doRequest<AuthToken>(
        post(dispatch, `magenta_gateway/iam/refreshToken`, {}, { token: _refreshToken }, false),
        (message, detail, statusCode) => new RequestError('read', message, detail, statusCode),
      );
      if (!data) {
        throw new Error('Token Refresh failed. Response is undefined.');
      }
      setTokens(data.access_token, data.refresh_token);
      return callback();
    } catch (error) {
      console.log('Error fetching refresh token: ', error);
      clearAndLogout(dispatch);
    }
  }
  clearAndLogout(dispatch);
  return { status: 'unauthorized' };
};

interface User {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  id: string;
}

export const getUser = async (dispatch: Dispatch): Promise<ResponseBody<RawUser>> => {
  const token = getToken();
  // const session_state = getSessionState();
  if (token) {
    try {
      const user = await doRequest<User>(
        get(dispatch, `magenta_gateway/iam/user`),
        (message, detail, statusCode) => new RequestError('read', message, detail, statusCode),
      );
      if (!user) {
        throw new RequestError('read', 'user could not be retrieved', '/iam/user failed.');
      }
      const auth = new Auth(user.username, user.firstName, user.lastName, user.email, user.id);
      localStorage.setItem('user', JSON.stringify(auth));
      return {
        status: 'ok',
        body: <RawUser>user,
      };
    } catch (error) {
      console.log(error);
      const requestError = error as RequestError;
      if (!requestError.statusCode) {
        requestError.statusCode = 500;
      }
      switch (requestError.statusCode) {
        case 401:
          return requestRefreshToken(getUser, dispatch);
        default: {
          return {
            status: 'error',
            message: requestError.message,
            detail: requestError.detail,
          };
        }
      }
    }
  } else {
    return {
      status: 'unauthorized',
    };
  }
};

export const doRequest = async <T, V = T>(
  promise: Promise<ResponseBody<T>>,
  errorHandler: (message: string, detail: unknown, status?: number) => Error,
  successHandler: (result: T) => V = t => t as unknown as V,
): Promise<V | undefined> => {
  const response = await promise;
  switch (response.status) {
    case 'ok':
    case 'string':
      return typeof response.body !== 'undefined' ? successHandler(response.body as T) : undefined;

    case 'error': {
      const { message = 'Error', detail = 'Error', statusCode } = response as ErrorResponse;
      throw errorHandler(message, detail, statusCode);
    }

    default:
      return undefined;
  }
};

type Args = [dispatch: Dispatch, url: string, params?: Params | undefined, body?: BodyType, requiresToken?: boolean];
const get = <T>(...args: Args) => request<T>('GET', ...args);
const put = <T>(...args: Args) => request<T>('PUT', ...args);
const post = <T>(...args: Args) => request<T>('POST', ...args);
const del = <T>(...args: Args) => request<T>('DELETE', ...args);
const patch = <T>(...args: Args) => request<T>('PATCH', ...args);

export { del, get, login, patch, post, put };
