import { t } from 'i18next';
import { modelSelectionActions } from 'model/slices/modelSelectionSlice';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';
import { abbreviateNumberToCompactString } from 'utils';

import { ChatBotDetails } from './ChatBotDetails';
import { Details } from './Details';

export const DefaultContent = () => {
  // TSEYE-4486
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(modelSelectionActions.modelSelectionEnable());
  }, []);

  const remaining_tokens = useSelector((state: TStore) => state.rateLimitReducer.remaining_tokens);
  return (
    <div className="mt-28 sm:mt-24">
      <ChatBotDetails />
      <div className="mt-4 grid grid-cols-2 gap-4 pb-14 sm:grid-cols-2">
        <div className="col-span-1">
          <Details
            title="rate limit details title"
            details={
              t('rate limit details part 1') +
              (remaining_tokens === null
                ? ' <an error occured and your rate limit is not known> '
                : ` ${abbreviateNumberToCompactString(remaining_tokens)} `) +
              t('rate limit details part 2')
            }
          />
        </div>
        <div className="col-span-1">
          <Details title="output style details title" details={t('output style details')} />
        </div>
      </div>
    </div>
  );
};
