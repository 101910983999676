import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEnglish from './translations/English/translation.json';
import translationGerman from './translations/German/translation.json';

const resources = {
  en: {
    translation: translationEnglish,
  },
  de: {
    translation: translationGerman,
  },
};

i18next.use(initReactI18next).init({
  resources,
  detection: { order: ['path', 'navigator'] },
  fallbackLng: 'de',
});

export default i18next;
