import { createSlice } from '@reduxjs/toolkit';

interface initialInterface {
  isCookieConsentAccepted: boolean;
  openCookieBanner: boolean;
  showAcceptCookieContent: boolean;
}

const initialState: initialInterface = {
  isCookieConsentAccepted: false,
  openCookieBanner: true,
  showAcceptCookieContent: true,
};

const cookieConsentSlice = createSlice({
  name: 'cookieConsent',
  initialState,
  reducers: {
    acceptCookieConsent: (state = initialState, actions) => {
      state.isCookieConsentAccepted = actions.payload;
    },
    openCookieDialog: (state = initialState, actions) => {
      state.openCookieBanner = actions.payload;
    },
    displayAcceptCookieContent: (state = initialState, actions) => {
      state.showAcceptCookieContent = actions.payload;
    },
    reset: () => initialState,
  },
});

export const { actions: cookieConsentActions } = cookieConsentSlice;
export const cookieConsentReducer = cookieConsentSlice.reducer;
