import { createSlice } from '@reduxjs/toolkit';

interface initialInterface {
  isPromptDialogOpen: boolean;
  isAIPromptMainPageOpen: boolean;
  isPromptDialogClosedByUser: boolean;
  showBubbleOnPromptIcon: boolean;
}

const initialState: initialInterface = {
  isPromptDialogOpen: false,
  isAIPromptMainPageOpen: false,
  isPromptDialogClosedByUser: false,
  showBubbleOnPromptIcon: false,
};

const promptDialogSlice = createSlice({
  name: 'promptDialog',
  initialState,
  reducers: {
    openPromptDialog: (state = initialState) => {
      state.isPromptDialogOpen = true;
    },
    closePromptDialog: (state = initialState) => {
      state.isPromptDialogOpen = false;
      state.isPromptDialogClosedByUser = true;
    },
    openAIPromptScreen: (state = initialState) => {
      state.isAIPromptMainPageOpen = true;
    },
    closeAIPromptScreen: (state = initialState) => {
      state.isAIPromptMainPageOpen = false;
    },
    openBubbleOnPromptIcon: (state = initialState) => {
      state.showBubbleOnPromptIcon = true;
    },
    closeBubbleOnPromptIcon: (state = initialState) => {
      state.showBubbleOnPromptIcon = false;
    },
  },
});

export const {
  openPromptDialog,
  closePromptDialog,
  openAIPromptScreen,
  closeAIPromptScreen,
  openBubbleOnPromptIcon,
  closeBubbleOnPromptIcon,
} = promptDialogSlice.actions;
export const promptDialogReducer = promptDialogSlice.reducer;
