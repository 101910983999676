import { createSlice } from '@reduxjs/toolkit';

interface initialInterface {
  pseudonymization: boolean;
}

const initialState: initialInterface = {
  pseudonymization: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updatePseudonymizationFlag: (state = initialState, action) => {
      state.pseudonymization = action.payload;
    },
  },
});

export const { actions: settingsActions } = settingsSlice;
export const settingsReducer = settingsSlice.reducer;
