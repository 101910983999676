import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SessionUploadedFiles from 'components/SessionUploadedFiles';
import { t } from 'i18next';
import FileUpload from 'layout/footerController/FileUpload';
import { TextFieldActionButton } from 'layout/footerController/TextFieldActionButton';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TStore } from 'store';

import { Loader } from '../loader';

interface UserInputProps {
  onSubmit: (inputText: string) => void;
  openAIPromptMainScreen: () => void; //TSEYE-4446
  typing: boolean;
}

export const UserInput: React.FC<UserInputProps> = ({
  onSubmit,
  // openAIPromptMainScreen,
  typing,
}) => {
  const [inputText, setInputText] = useState('');
  const remaining_tokens = useSelector((state: TStore) => state.rateLimitReducer.remaining_tokens) || 0;
  const uploadedFiles = useSelector((state: TStore) => state.uploadedFilesReducer.uploadedFiles);
  const uploadingFilesInProgress = useSelector((state: TStore) => state.uploadedFilesReducer.loading);
  // const [textFieldPosition, setTextFieldPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  // const textFieldRef = useRef<HTMLDivElement | null>(null);
  const isPromptDialogOpen = useSelector((state: TStore) => state.promptDialogReducer.isPromptDialogOpen);
  // const isPromptDialogClosedByUser = useSelector(
  //   (state: TStore) => state.promptDialogReducer.isPromptDialogClosedByUser,
  // );
  // const isAIPromptMainPageOpen = useSelector((state: TStore) => state.promptDialogReducer.isAIPromptMainPageOpen);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(event.target.value);

    // if (!isAIPromptMainPageOpen) {
    //   dispatch(openBubbleOnPromptIcon());
    // }
  };

  const handlePromptDialog = () => {
    // for prompt pop-up
    if (isPromptDialogOpen) {
      return;
    }

    // if (inputText && inputText.length > 0 && !isPromptDialogClosedByUser) {
    //   dispatch(openPromptDialog());
    // }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (inputText.trim() !== '') {
      onSubmit(inputText.replace(/\\r\\n/g, '\n'));
      setInputText('');
    }
  };

  // const handleClosePromptPopUpDialog = () => {
  //   dispatch(closePromptDialog());
  // };

  const inputLines = inputText.split('\n').length;
  // useEffect(() => {
  //   if (textFieldRef.current) {
  //     const textFieldPosition = textFieldRef.current.getBoundingClientRect();
  //     setTextFieldPosition(textFieldPosition);
  //     console.log('textFieldPosition details --> ', textFieldPosition);
  //   }
  // }, [textFieldRef]);

  return (
    <div className="mt-4 flex w-full flex-col rounded-lg bg-white sm:px-0">
      {/* Display uploaded file component - new code */}
      {uploadedFiles.length > 0 && <SessionUploadedFiles />}
      <Box component="form" noValidate autoComplete="off">
        <div className="flex flex-col">
          <TextField
            disabled={typing || remaining_tokens < 0 || uploadingFilesInProgress}
            multiline
            autoComplete="off"
            maxRows={4}
            // ref={textFieldRef}
            value={inputText}
            onChange={handleInputChange}
            onMouseLeave={handlePromptDialog}
            onKeyDown={handleKeyDown}
            placeholder={t('send a message')}
            fullWidth
            InputProps={{
              classes: {
                root: uploadedFiles.length > 0 ? 'pt-0 mt-0 text-sm' : 'mt-0 text-sm',
                focused: 'text-base',
                notchedOutline: 'border-none',
              },
              endAdornment: (
                <InputAdornment position="end">
                  {typing || uploadingFilesInProgress ? (
                    <div id="promptLoader" className="flex h-[100%] items-center justify-center">
                      <Loader size={24} />
                    </div>
                  ) : (
                    <div className="flex gap-4">
                      <FileUpload inputLines={inputLines} />
                      <TextFieldActionButton
                        onSubmit={handleSubmit}
                        disabled={inputText ? false : true}
                        inputLines={inputLines}
                        icon={<SendRoundedIcon />}
                      />
                    </div>
                  )}
                </InputAdornment>
              ),
              ...(inputLines > 4 && {
                inputProps: {
                  className: 'pr-4 sm:pr-16',
                },
              }),
            }}
            className="rounded-lg bg-white font-teleneo text-sm text-inputtext"
            id="userPromptInput"
          />
        </div>
      </Box>
      {/* Pop-up Modal for Prompt Template - starts */}
      {/* <PromptsPopUpModal openAIPromptMainScreen={openAIPromptMainScreen} onClose={handleClosePromptPopUpDialog} /> */}
      {/* Pop-up Modal for Prompt Template - ends */}
    </div>
  );
};
