import React from 'react';

import { Loader } from '../loader';

export const PageLoader = () => {
  return (
    <div className="mt-40 flex h-screen items-center justify-center">
      <Loader />
    </div>
  );
};
