import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CustomTooltip } from 'components/utilityComponents/CustomTooltip';
import { SourceFileInfo } from 'model/services/promt/types';
import React from 'react';

import { OutputSourceContents } from './OutputSourceContents';

interface OutputSourceModalProps {
  open: boolean;
  onClose: () => void;
  sourceContent: SourceFileInfo | undefined;
}

export const OutputSourceModal: React.FC<OutputSourceModalProps> = ({ open, onClose, sourceContent }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="output-source-dialog-title"
        aria-describedby="output-source-dialog-description"
        classes={{ paper: 'bg-grey-background' }}
        className="px-6 pb-6"
      >
        <DialogTitle id="output-source-dialog-title" className="flex justify-between">
          <span className="line-clamp-1 w-4/5 font-bold leading-9">
            <CustomTooltip title={sourceContent?.fileName ?? ''}>{sourceContent?.fileName ?? ''}</CustomTooltip>
          </span>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" className="cursor-pointer" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="mb-[24px] pb-0">
          <DialogContentText id="output-source-dialog-description">
            {sourceContent &&
              sourceContent.fileDetails.map((file, index, array) => (
                <OutputSourceContents dialogContent={file} key={index} isLastItem={index === array.length - 1} />
              ))}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
