import { createSlice } from '@reduxjs/toolkit';

interface initialInterface {
  used_tokens: number | null;
  token_allocated: number | null;
  user_id: string | null;
  remaining_tokens: number | null;
  contact_email: string | null;
}

const initialState: initialInterface = {
  used_tokens: 0,
  user_id: null,
  token_allocated: null,
  remaining_tokens: null,
  contact_email: null,
};

const rateLimitSlice = createSlice({
  name: 'rateLimit',
  initialState,
  reducers: {
    updateRateLimitInfo: (state = initialState, action) => {
      state.used_tokens = action.payload.token_used;
      state.token_allocated = action.payload.token_allocated;
      state.remaining_tokens = action.payload.remaining_tokens;
      state.contact_email = action.payload.contact_email;
    },
    reset: () => initialState,
  },
});

export const { actions: rateLimitActions } = rateLimitSlice;
export const rateLimitReducer = rateLimitSlice.reducer;
