import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import { Dialog, DialogContent, DialogTitle, IconButton, styled, Switch } from '@mui/material';
import CustomSnackbar from 'components/chat/CustomSnackbar';
import { t } from 'i18next';
import { fetchGlobalContextSetting } from 'model/services/globalSettings/fetchGlobalContextSetting';
import { updatePseudonymization } from 'model/services/globalSettings/fetchPseudonymization';
import { settingsActions } from 'model/slices/settings';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';

interface ISettingsModalProps {
  open: boolean;
  handleClose: (open: boolean) => void;
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 28,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 19,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(10px)',
    },
    '& + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#e20074',
      border: '2px solid #e20074',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 6,
    '&.Mui-checked': {
      padding: 4,
      transform: 'translateX(14px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#e20074',
        border: '2px solid #e20074',
      },
      '& .MuiSwitch-thumb': {
        width: 20,
        height: 20,
        backgroundColor: '#fff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 15,
    height: 15,
    borderRadius: 10,
    backgroundColor: '#AFAFAF',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 44 / 2,
    opacity: 1,
    backgroundColor: '#fff',
    border: '2px solid #AFAFAF',
    boxSizing: 'border-box',
  },
}));

export function SettingsModal({ open, handleClose }: ISettingsModalProps) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const pseudonymizationChecked = useSelector((state: TStore) => state.settingsReducer.pseudonymization);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getPseudonymizationFlag() {
      const selectedUserSetting = await fetchGlobalContextSetting(dispatch);
      if (selectedUserSetting) {
        dispatch(settingsActions.updatePseudonymizationFlag(selectedUserSetting.config.PSEUDONYMIZATION == 'True'));
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage('magenta_gateway/pseudonymization_flag');
      }
    }

    getPseudonymizationFlag();
  }, []);

  const handlePseudonymizationChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(settingsActions.updatePseudonymizationFlag(event.target.checked));
    const config = {
      config: {
        PSEUDONYMIZATION: event.target.checked ? 'True' : 'False',
      },
    };
    try {
      await updatePseudonymization(dispatch, config, errorCallback);
    } catch (error) {
      //TODO error message.
    }
  };

  const errorCallback = () => {
    setSnackbarOpen(true);
    setSnackbarMessage('magenta_gateway/pseudonymization_flag');
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth={true}
      >
        <DialogTitle className="flex items-center justify-between pl-5">
          <h4 className="font-bold">{t('Settings')}</h4>
          <IconButton aria-label="open drawer" onClick={() => handleClose(false)} size="small">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="flex items-start gap-16 pt-4">
          <div className="flex items-center justify-between gap-2">
            <SettingsIcon />
            <span>{t('General')}</span>
          </div>
          {/* <Button variant="text" startIcon={<SettingsIcon />} className="text-black capitalize" size="small">
                {t('General')}
                </Button> */}
          <div className="flex flex-col">
            <div className="flex gap-2 pb-3">
              <div className="flex flex-col gap-1">
                <span>{t('Pseudonymization setting title')}</span>
                <p>{t('Pseudonymization setting description')}</p>
              </div>
              <AntSwitch checked={pseudonymizationChecked} onChange={handlePseudonymizationChange} />
            </div>
            {/* <Divider />
                    <div className="flex justify-between items-center mt-4">
                        <span>{t('Clear all chats')}</span>
                        <Button variant="contained" size="medium" className="mr-2 bg-magenta text-white">
                            {t('Clear')}
                        </Button>
                    </div> */}
          </div>
        </DialogContent>
      </Dialog>

      <CustomSnackbar open={snackbarOpen} message={snackbarMessage} onClose={handleSnackbarClose} />
    </>
  );
}
