import { t } from 'i18next';
import React from 'react';

export const ChatBotDetails = () => {
  return (
    <div className="h-full rounded-lg bg-white p-6 font-teleneo">
      <h2 className="mb-4 text-xl font-medium">{t('AI with data privacy')}</h2>
      <p className="mb-4 text-xs leading-snug">{t('chatbot description')}</p>
      <p className="mb-4 text-xs leading-snug">{t('chatbot-capabilities.text')}</p>
      <div className="mb-1 text-xs leading-snug">
        <ul className="ml-6">
          <li>{t('chatbot-capabilities.capabilities.0')}</li>
          <li>{t('chatbot-capabilities.capabilities.1')}</li>
          <li>{t('chatbot-capabilities.capabilities.2')}</li>
          <li>{t('chatbot-capabilities.capabilities.3')}</li>
          <li>{t('chatbot-capabilities.capabilities.4')}</li>
        </ul>
      </div>
    </div>
  );
};
