import IconButton from '@mui/material/IconButton';
import React from 'react';

interface TextFieldActionButtonProps {
  onSubmit: () => void;
  inputLines: number;
  disabled?: boolean;
  icon: JSX.Element;
  children?: React.ReactNode;
}

export const TextFieldActionButton: React.FC<TextFieldActionButtonProps> = ({
  onSubmit,
  inputLines,
  disabled = false,
  icon,
  children,
}) => {
  return (
    <IconButton
      className={`${inputLines > 4 ? 'absolute right-12' : ''}`}
      size="medium"
      onClick={onSubmit}
      id="sendPromptRequestButton"
      disabled={disabled}
    >
      {icon}
      {children}
    </IconButton>
  );
};
