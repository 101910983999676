import { Dispatch } from '@reduxjs/toolkit';
import { catchErrorUtils } from 'utils/catchErrorUtils';

import { doRequest, get } from '../../../domain/api';
import RequestError from '../../../domain/exceptions/requestError';
import { ModelSelectionInterface } from './types';

export const fetchModelSelection = async (
  dispatch: Dispatch,
  user: string,
  session_id: string,
): Promise<ModelSelectionInterface[] | undefined> => {
  try {
    const result = await doRequest<ModelSelectionInterface[] | undefined>(
      get(dispatch, `magenta_gateway/session/config/${session_id}`),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );

    return result;
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};
