import { CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import React from 'react';

const theme = createTheme({
  palette: {
    primary: {
      main: '#e20074',
    },
  },
});

interface IProps {
  size?: number;
}
export const Loader = ({ size }: IProps) => {
  return (
    <ThemeProvider theme={theme}>
      <CircularProgress size={size} thickness={4} />
    </ThemeProvider>
  );
};
