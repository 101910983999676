import { t } from 'i18next';
import { FileDetails } from 'model/services/promt/types';
import React, { useState } from 'react';

interface OutputSourceContentsProps {
  dialogContent: FileDetails | undefined;
  isLastItem: boolean;
}

export const OutputSourceContents: React.FC<OutputSourceContentsProps> = ({ dialogContent, isLastItem }) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <>
      <div className="grid grid-cols-10">
        <div className="col-span-2 px-2 py-1 text-magenta">
          {t('Page')}: {dialogContent && dialogContent.page}
        </div>
        <div
          className={`col-span-8 overflow-hidden px-2 py-1 text-[14px] text-inputtext ${
            showMore ? '' : 'line-clamp-3'
          }`}
        >
          {dialogContent && dialogContent.chunk}
        </div>
      </div>
      <div className="text-right text-xs text-magenta">
        <button onClick={() => setShowMore(!showMore)}>{showMore ? t('Show Less') : t('Show More')}</button>
      </div>
      {isLastItem ? null : <hr className="my-2 border-[#AFAFAF]" />}
    </>
  );
};
