import { Dispatch } from '@reduxjs/toolkit';
import { fetchRateLimitCount } from 'model/services/rateLimits/fetchRateLimits';
import { closeAIPromptScreen } from 'model/slices/aiPromptLib';
import { appActions } from 'model/slices/appSlice';
import { conversationsActions } from 'model/slices/conversationsSlice';
import { cookieConsentActions } from 'model/slices/cookiesSlice';
import { filesAction } from 'model/slices/filesSlice';
import { globalUploadActions } from 'model/slices/globalUpload';
import { modelSelectionActions } from 'model/slices/modelSelectionSlice';
import { outputStyleActions } from 'model/slices/outputStyleSlice';
import { rateLimitActions } from 'model/slices/rateLimit';

export function onNewChatScreenClick(
  dispatch: Dispatch,
  windowInnerWidth: number,
  showDefaultContent: boolean,
  selectedModel: string,
) {
  if (windowInnerWidth < 640) {
    dispatch(appActions.setDrawerState(false));
  }
  if (!showDefaultContent) {
    dispatch(conversationsActions.setActiveConversation(null));
    dispatch(filesAction.reset());
  }
  dispatch(conversationsActions.setActivatePastConversationType(false));
  dispatch(appActions.setShowDefaultContent(true));
  dispatch(modelSelectionActions.setModelSelection(selectedModel));
  dispatch(appActions.setShowChatScreen(false));
  dispatch(closeAIPromptScreen()); //TSEYE-4446
  dispatch(globalUploadActions.closeGlobalUpload());
  dispatch(conversationsActions.setCurrentConversationChat([]));
  dispatch(outputStyleActions.reset());
  dispatch(conversationsActions.clearPromptResponse());
}

export async function updateRateLimitCount(dispatch: Dispatch, errorCallback?: () => void) {
  try {
    const newRateLimitCount = await fetchRateLimitCount(dispatch);
    dispatch(rateLimitActions.updateRateLimitInfo(newRateLimitCount));
    if (!newRateLimitCount) {
      errorCallback?.();
    }
  } catch (error) {
    console.log('Error in fetching the rate limit token count !', error);
    errorCallback?.();
  }
}

export const handleLogout = (dispatch: Dispatch, onClose = () => {}) => {
  onClose();
  dispatch(appActions.reset());
  dispatch(conversationsActions.reset());
  dispatch(cookieConsentActions.reset());
  dispatch(filesAction.reset());
  dispatch(globalUploadActions.reset());
  dispatch(modelSelectionActions.reset());
  dispatch(outputStyleActions.reset());
  dispatch(rateLimitActions.reset());
  dispatch(appActions.logout());
  console.log('Logout login to be added');
};
