import HelpCenterTwoToneIcon from '@mui/icons-material/HelpCenterTwoTone';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { getCurrentDateTime } from 'utils';

interface Props {
  errMsg?: string;
}

const FailedPromptResponse: React.FC<Props> = ({ errMsg }) => {
  const [currentDateTime, setCurrentDateTime] = useState<string>(getCurrentDateTime());

  useEffect(() => {
    setCurrentDateTime(getCurrentDateTime());
  }, []);

  return (
    <div className="failed-prompt-response">
      <HelpCenterTwoToneIcon fontSize="small" />
      <span className="ml-2">
        {t('While querying the AI, the following error occurred')}: {errMsg}
      </span>
      <p>
        {t(
          'Please report the occurred error to the Deutsche Telekom team. You may try your prompt again in a new chat.',
        )}
      </p>
      <strong>{currentDateTime}</strong>
    </div>
  );
};

export default FailedPromptResponse;
