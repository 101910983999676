import { createSlice } from '@reduxjs/toolkit';

interface initialInterface {
  selectedTemperature: number;
}

const initialState: initialInterface = {
  selectedTemperature: 0.5,
};

const outputStyleSlice = createSlice({
  name: 'outputStyle',
  initialState,
  reducers: {
    setTemperature: (state = initialState, action) => {
      state.selectedTemperature = action.payload;
    },
    reset: () => initialState,
  },
});

export const { actions: outputStyleActions } = outputStyleSlice;
export const outputStyleReducer = outputStyleSlice.reducer;
