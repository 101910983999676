import { Dispatch } from '@reduxjs/toolkit';
import { del, doRequest, get, post } from 'domain/api';
import RequestError from 'domain/exceptions/requestError';
import { FileUploadOrDeleteResponse } from 'model/services/files/types';
import { filesAction } from 'model/slices/filesSlice';
import { catchErrorUtils } from 'utils/catchErrorUtils';

import { SessionFileInfo } from '../globalUpload/types';

export const uploadFiles = async ({
  dispatch,
  data,
  sessionId,
}: {
  dispatch: Dispatch;
  data: FormData;
  sessionId: string;
}): Promise<FileUploadOrDeleteResponse | undefined> => {
  try {
    dispatch(filesAction.setLoader(true));
    return await doRequest<FileUploadOrDeleteResponse>(
      post(dispatch, `magenta_gateway/session_files/${sessionId}`, undefined, data),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
      (data: FileUploadOrDeleteResponse) => {
        dispatch(filesAction.setLoader(false));
        return data;
      },
    );
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};

export const fetchSessionFilesList = async (
  dispatch: Dispatch,
  sessionId: string,
): Promise<SessionFileInfo[] | undefined> => {
  try {
    return await doRequest<SessionFileInfo[]>(
      get(dispatch, `magenta_gateway/session_files/${sessionId}`),
      (message, detail, statusCode) => new RequestError('read', message, detail, statusCode),
    );
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};

export const deleteFiles = async ({
  dispatch,
  data,
  sessionId,
}: {
  dispatch: Dispatch;
  data: [string];
  sessionId: string;
}): Promise<FileUploadOrDeleteResponse | undefined> => {
  try {
    return await doRequest<FileUploadOrDeleteResponse>(
      del(dispatch, `magenta_gateway/session_files/${sessionId}`, undefined, data),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};

export const fetchUploadingStatus = async (dispatch: Dispatch, fileName: string) => {
  try {
    const sessionFileUploadedStatus = await doRequest<[string]>(
      get(dispatch, `magenta_gateway/session_files/progress/${fileName}`),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );
    return typeof sessionFileUploadedStatus === 'number' ? sessionFileUploadedStatus : 10;
  } catch (error) {
    console.log('Error in fetching uploading progress', error);
    return 0;
  }
};
