import { Popover } from '@mui/material';
import { SidebarFooterButton } from 'components';
import { UserMenu } from 'components/userProfileMenu';
import React, { useState } from 'react';
interface UserprofileProps {}
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

export const UserProfileComponent: React.FC<UserprofileProps> = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'user-menu-popover' : undefined;

  return (
    <>
      <SidebarFooterButton handleClick={handleClick} title="User Profile" Icon={<FolderOpenIcon />} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        elevation={2}
      >
        <UserMenu onClose={handleClose} />
      </Popover>
    </>
  );
};

export default UserProfileComponent;
