import React from 'react';
import { useTranslation } from 'react-i18next';

interface DetailsProps {
  title: string;
  details: string;
}

export const Details: React.FC<DetailsProps> = ({ title, details }) => {
  const { t } = useTranslation();
  return (
    <div className="h-full rounded-lg bg-white p-6">
      <h2 className="mb-3 text-xl font-medium">{t(`${title}`)}</h2>
      <p className="mb-3 text-xs leading-snug">{details}</p>
    </div>
  );
};
