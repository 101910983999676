import AttachFileIcon from '@mui/icons-material/AttachFile';
import { styled } from '@mui/material/styles';
import CustomSnackbar from 'components/chat/CustomSnackbar';
import { createConversations } from 'model/services/conversations/createConversation';
import { fetchConversations } from 'model/services/conversations/fetchConversations';
import { fetchSessionFilesList, uploadFiles } from 'model/services/files/fetchFiles';
import { conversationsActions } from 'model/slices/conversationsSlice';
import { filesAction } from 'model/slices/filesSlice';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';
import { formatBytes } from 'utils';

import { TextFieldActionButton } from './TextFieldActionButton';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

interface FileUploadProps {
  inputLines: number;
}

const RAG_ENABLED = process.env?.REACT_APP_RAG_ENABLED === 'true';

const FileUpload: React.FC<FileUploadProps> = ({ inputLines }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const uploadedFiles = useSelector((state: TStore) => state.uploadedFilesReducer.uploadedFiles);
  const activeConversation = useSelector((state: TStore) => state.conversationsReducer.activeConversation);
  const showDefaultContent = useSelector((state: TStore) => state.appReducer.showDefaultContent);
  // const uploadError = useSelector((state: TStore) => state.appReducer.error);
  const dispatch = useDispatch();

  const onAddFile = () => {
    try {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileNames = Array.from(event.target.files).map(file => file.name);

      if (uploadedFiles && uploadedFiles.length + fileNames.length <= 3) {
        dispatch(filesAction.setUploadingState(true));
        dispatch(filesAction.setSessionFileCondition(true));
        const files = Array.from(event.target.files);
        const user = localStorage.getItem('user');
        const user_id = JSON.parse(user as string).username;
        const currTimeStamp = new Date().getTime();
        const sessionId = activeConversation?.sessionId || `session_${currTimeStamp}`;
        const data = new FormData();
        files.forEach(file => {
          data.append(`files`, file, file.name);
        });

        if (showDefaultContent && !activeConversation) {
          await createConversations(dispatch, user_id, sessionId);
          dispatch(
            conversationsActions.setActiveConversation({
              sessionId: sessionId,
              sessionTitle: sessionId,
              updatedAt: '',
            }),
          );
          if (user) {
            const conversations = await fetchConversations(dispatch);
            dispatch(conversationsActions.updateConversations(conversations));
          }
        }

        // Construct an array with actual file names
        const actualFileNames = files.map(file => ({
          name: file.name,
          hash: '',
          size: formatBytes(file.size),
        }));

        // Dispatch action to update uploaded files list with actual file names
        dispatch(filesAction.updateUploadedFilesList(actualFileNames));

        try {
          // Dispatch action to add files to uploading
          dispatch(filesAction.addFilesToUpload(fileNames));

          // Upload files
          const response = await uploadFiles({ dispatch, data, sessionId: sessionId });

          if (response) {
            // Fetch session files after successful upload
            const sessionFiles = await fetchSessionFilesList(dispatch, sessionId);
            dispatch(filesAction.updateUploadedFilesList(sessionFiles));
          } else {
            setSnackbarOpen(true);
            setSnackbarMessage('magenta_gateway/session_files');
          }
        } catch (error) {
          console.error('Error uploading files:', error);
          setSnackbarOpen(true);
          setSnackbarMessage('magenta_gateway/session_files');
        } finally {
          // Remove files from uploading
          dispatch(filesAction.removeFilesFromUploading());
        }
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage('magenta_gateway/session_files');
      }
      event.target.value = '';
    }
  };

  return (
    <>
      <TextFieldActionButton
        disabled={RAG_ENABLED}
        inputLines={inputLines}
        onSubmit={onAddFile}
        icon={<AttachFileIcon />}
      >
        <VisuallyHiddenInput
          multiple
          onChange={handleFileChange}
          type="file"
          accept=".msg,.doc,.docx,.pdf,.txt,.xlsx,.xls,.csv"
          ref={fileInputRef}
          id="sessionFileUploadBtn"
        />
      </TextFieldActionButton>
      <CustomSnackbar open={snackbarOpen} message={snackbarMessage} onClose={handleSnackbarClose} />
    </>
  );
};

export default FileUpload;
