import { Dispatch } from '@reduxjs/toolkit';
import { catchErrorUtils } from 'utils/catchErrorUtils';

import { doRequest, get, post } from '../../../domain/api';
import RequestError from '../../../domain/exceptions/requestError';
import { ConfigPayload, GlobalSettingsData } from './types';

export const fetchPseudonymization = async (dispatch: Dispatch): Promise<GlobalSettingsData | undefined> => {
  try {
    return await doRequest<GlobalSettingsData>(
      get(dispatch, 'magenta_gateway/user/pseudonymization'),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};

export const updatePseudonymization = async (
  dispatch: Dispatch,
  configPayload: ConfigPayload,
  errorCallback?: () => void,
) => {
  try {
    const payload = {
      configPayload,
    };

    return await doRequest(
      post(dispatch, `magenta_gateway/user/settings`, {}, { ...payload.configPayload }),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );
  } catch (error) {
    errorCallback?.();
    catchErrorUtils(dispatch, error);
  }
};
