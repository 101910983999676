import { LoginPage, MainPage, NotFoundPage, RestorePasswordPage, TermsPage } from 'pages';
import React from 'react';
import { RouteProps } from 'react-router-dom';

export type RootRoutesProps = RouteProps & {
  authOnly?: boolean;
};
export enum RootRoutes {
  MAIN = 'main',
  TERMS = 'privacy',
  LOGIN = 'login',
  RESTORE_PASSWORD = 'restore_password',
  NOT_FOUND = 'not_found',
}

export const RoutePath: Record<RootRoutes, string> = {
  [RootRoutes.MAIN]: '/main',
  [RootRoutes.LOGIN]: '/login',
  [RootRoutes.TERMS]: '/privacy',
  [RootRoutes.RESTORE_PASSWORD]: '/restore-password',
  [RootRoutes.NOT_FOUND]: '*',
};

export const routeConfig: Record<RootRoutes, RootRoutesProps> = {
  [RootRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
    authOnly: true,
  },
  [RootRoutes.LOGIN]: {
    path: RoutePath.login,
    element: <LoginPage />,
  },
  [RootRoutes.TERMS]: {
    path: RoutePath.privacy,
    element: <TermsPage />,
  },
  [RootRoutes.RESTORE_PASSWORD]: {
    path: RoutePath.restore_password,
    element: <RestorePasswordPage />,
  },
  [RootRoutes.NOT_FOUND]: {
    path: RoutePath.not_found,
    element: <NotFoundPage />,
  },
};
