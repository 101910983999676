import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { t } from 'i18next';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { handleLogout } from 'utils/chatUtils';

import { AppVersionModel } from './AppVersionModel';
import { GlobalContextTurnOffSetting } from './GlobalContextTurnOffSetting';
import { SettingsModal } from './SettingsModal';

interface UserMenuProps {
  onClose: () => void;
}

export const UserMenu: React.FC<UserMenuProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const [openSettingsModal, setOpenSettingsModal] = React.useState(false);
  const [openAppVersionModal, setopenAppVersionModal] = React.useState(false);
  const [openGlobalContextSettingModal, setOpenGlobalContextSettingModal] = React.useState(false);

  const handleAppVersionModal = () => {
    setopenAppVersionModal(true);
  };

  const settingRowClass = 'grid cursor-pointer grid-cols-5 p-2 hover:bg-grey-background';

  return (
    <div
      className={`w-[151px] rounded border border-gray-200 bg-white py-2 shadow-buttonBox md:w-[181px] lg:w-[211px]`}
    >
      <div className={settingRowClass} onClick={() => setOpenGlobalContextSettingModal(true)}>
        <div className="col-span-1 text-settingIconColor">
          <BuildCircleOutlinedIcon fontSize="medium" color="inherit" />
        </div>
        <div className="col-span-4">{t('Chat Settings')}</div>
      </div>
      <div className={settingRowClass} onClick={() => setOpenSettingsModal(true)}>
        <div className="col-span-1 text-settingIconColor">
          <SettingsIcon color="inherit" />
        </div>
        <div className="col-span-4">{t('Settings')}</div>
      </div>
      <div className={settingRowClass} onClick={() => handleLogout(dispatch, onClose)}>
        <div className="col-span-1 text-settingIconColor">
          <LogoutIcon fontSize="medium" color="inherit" />
        </div>
        <div className="col-span-4">{t('Logout')}</div>
      </div>
      <div className={settingRowClass} onClick={handleAppVersionModal}>
        <div className="col-span-1 text-settingIconColor">
          <InfoOutlinedIcon color="inherit" />
        </div>
        <div className="col-span-4">{t('Version Information')}</div>
      </div>
      <SettingsModal open={openSettingsModal} handleClose={setOpenSettingsModal} />
      <AppVersionModel
        open={openAppVersionModal}
        onClose={() => {
          setopenAppVersionModal(false);
        }}
      />
      <GlobalContextTurnOffSetting
        open={openGlobalContextSettingModal}
        handleClose={setOpenGlobalContextSettingModal}
      />
    </div>
  );
};
