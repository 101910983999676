import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface WindowDimensions {
  windowInnerHeight: number;
  windowInnerWidth: number;
}

function getWindowDimensions(): WindowDimensions {
  const { innerWidth: windowInnerWidth, innerHeight: windowInnerHeight } = window;
  return {
    windowInnerHeight,
    windowInnerWidth,
  };
}

export default function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  // eslint-disable-next-line
  const { i18n } = useTranslation();

  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
