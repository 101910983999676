import { Dispatch } from '@reduxjs/toolkit';
import { catchErrorUtils } from 'utils/catchErrorUtils';

import { doRequest, get } from '../../../domain/api';
import RequestError from '../../../domain/exceptions/requestError';
import { RateLimitData } from './types';

export const fetchRateLimitCount = async (dispatch: Dispatch): Promise<RateLimitData | undefined> => {
  try {
    return await doRequest<RateLimitData>(
      get(dispatch, 'magenta_gateway/rate-limits/global'),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};
