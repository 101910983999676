import './i18n';
import './root-tailwind.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FallBack } from 'components';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Root from '../src/root';
import { store } from './store';

const container = document.getElementById('root');
const root = createRoot(container!);

const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: container,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: container,
      },
    },
    MuiModal: {
      defaultProps: {
        container: container,
      },
    },
  },
});

root.render(
  <Suspense fallback={''}>
    <ErrorBoundary FallbackComponent={FallBack} onReset={() => console.log('reset')}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Root />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </Suspense>,
);
