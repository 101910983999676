import i18n from 'i18n';

export function abbreviateNumberToCompactString(n: number): string {
  return Intl.NumberFormat(i18n.language === 'de' ? 'de-DE' : 'en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(n);
}

export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 B';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${(bytes / Math.pow(k, i)).toFixed(dm)} ${sizes[i]}`;
}

export const countUpperCaseLowerCaseDigits = (tokenStr: string) => {
  let upperCaseCount = 0;
  let lowerCaseCount = 0;
  let digitCount = 0;

  for (let i = 0; i < tokenStr.length; i++) {
    if (tokenStr[i] >= 'A' && tokenStr[i] <= 'Z') {
      upperCaseCount++;
    } else if (tokenStr[i] >= 'a' && tokenStr[i] <= 'z') {
      lowerCaseCount++;
    } else if (tokenStr[i] >= '0' && tokenStr[i] <= '9') {
      digitCount++;
    }
  }

  return {
    uppercase: upperCaseCount,
    lowercase: lowerCaseCount,
    digits: digitCount,
  };
};

export const getCurrentDateTime = (): string => {
  const now = new Date();

  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = String(now.getFullYear()).slice(-2);

  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  const formattedDateTime = `${day}.${month}.${year}  ${hours}:${minutes}`;

  return formattedDateTime;
};
