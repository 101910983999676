import { Slide } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { t } from 'i18next';
import React from 'react';

interface CustomSnackbarProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, message, onClose }) => {
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={props => <Slide {...props} direction="left" />}
        className={`${open ? 'flex' : 'hidden'} top-[74px]`}
      >
        <Alert onClose={onClose} severity="error" className="bg-red-200 text-sm font-semibold">
          <p>{` ${t('The server did not respond correctly to a request to')}: ${message}.`}</p>
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomSnackbar;
