import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { t } from 'i18next';
import {
  fetchGlobalContextSetting,
  updateGlobalContextSetting,
} from 'model/services/globalSettings/fetchGlobalContextSetting';
import { ConfigPayload } from 'model/services/globalSettings/types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface GlobalContextTurnOffSettingProps {
  open: boolean;
  handleClose: (open: boolean) => void;
}

export function GlobalContextTurnOffSetting({ open, handleClose }: GlobalContextTurnOffSettingProps) {
  const [ignorePreviousMessages, setIgnorePreviousMessages] = useState(false);
  const [ignoreSharedFiles, setIgnoreSharedFiles] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserSetting = async () => {
      const selectedUserSetting = await fetchGlobalContextSetting(dispatch);
      if (selectedUserSetting) {
        setIgnorePreviousMessages(selectedUserSetting.config.HISTORY_ENABLED != 'True');
        setIgnoreSharedFiles(selectedUserSetting.config.GLOBAL_CONTEXT_ENABLED != 'True');
      }
    };
    fetchUserSetting();
  }, []);

  const handleSave = async () => {
    const configPayload: ConfigPayload = {
      config: {
        HISTORY_ENABLED: ignorePreviousMessages ? 'False' : 'True',
        GLOBAL_CONTEXT_ENABLED: ignoreSharedFiles ? 'False' : 'True',
      },
    };

    try {
      const response = await updateGlobalContextSetting(dispatch, configPayload);
      if (response) {
        handleClose(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth={true}
        PaperProps={{ style: { width: '500px' } }}
      >
        <DialogTitle className="flex h-16 w-[500px] items-center justify-between p-0">
          <div className="px-6 py-4 text-[20px] text-[#202020]">{t('Chat Settings')}</div>
        </DialogTitle>
        <DialogContent className="h-[228px] px-6 pb-5 pt-0">
          <div className="h-[208px]">
            <div className="flex h-[114px] flex-col gap-2 border-t-[1px] border-dividerColor px-0 pb-4 pt-2">
              <div className="text-base">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: '#7C7C7C',
                          '&.Mui-checked': {
                            color: '#e20074',
                          },
                        }}
                        checked={ignorePreviousMessages}
                        onChange={e => setIgnorePreviousMessages(e.target.checked)}
                      />
                    }
                    label={t('Ignore previous messages')}
                  />
                </FormGroup>
              </div>
              <div className="px-3 py-0 text-xs text-[#7C7C7C]">
                {t(
                  'The AI stops using the previous messages from the chat history, but focuses solely on the current query. This also lowers the number of used tokens.',
                )}
              </div>
            </div>
            <div className="flex h-[94px] flex-col gap-2 border-t-[1px] border-dividerColor px-0 pb-4 pt-2">
              <div className="text-base">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: '#7C7C7C',
                          '&.Mui-checked': {
                            color: '#e20074',
                          },
                        }}
                        checked={ignoreSharedFiles}
                        onChange={e => setIgnoreSharedFiles(e.target.checked)}
                      />
                    }
                    label={t('Ignore Shared Files while generating')}
                  />
                </FormGroup>
              </div>
              <div className="px-3 py-0 text-xs text-[#7C7C7C]">
                {t('The AI only uses the files uploaded in the current conversation')}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="h-[74px] px-6 py-4">
          <Button
            variant="outlined"
            size="medium"
            className="h-9 w-[92px] border-btnBorder bg-white px-4 py-[6px] text-[14px] normal-case text-[#7C7C7C] hover:bg-grey-background"
            onClick={() => handleClose(false)}
          >
            {t('Cancel')}
          </Button>
          <Button
            variant="text"
            size="medium"
            className={'h-9 w-[92px] bg-magenta px-4 py-[6px] text-[14px] normal-case text-white hover:bg-darkmagenta'}
            onClick={handleSave}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
