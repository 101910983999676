import { createSlice } from '@reduxjs/toolkit';

import { FileInfo } from '../../services/globalUpload/types';

interface initialInterface {
  isGlobalUploadPageOpen: boolean;
  globalFileNames: FileInfo[];
}

const initialState: initialInterface = {
  isGlobalUploadPageOpen: false,
  globalFileNames: [],
};

const globalUploadSlice = createSlice({
  name: 'globalUpload',
  initialState,
  reducers: {
    openGlobalUpload: (state = initialState) => {
      state.isGlobalUploadPageOpen = true;
    },
    closeGlobalUpload: (state = initialState) => {
      state.isGlobalUploadPageOpen = false;
    },
    updateGlobalFileNames: (state = initialState, action) => {
      state.globalFileNames = action.payload;
    },
    reset: () => initialState,
  },
});

export const { actions: globalUploadActions } = globalUploadSlice;
export const globalUploadReducer = globalUploadSlice.reducer;
