import { Dispatch } from '@reduxjs/toolkit';
import { appActions } from 'model/slices/appSlice';

export const catchErrorUtils = async (dispatch: Dispatch, error: object | unknown) => {
  if (typeof error === 'object' && error !== null && 'message' in error && 'statusCode' in error && 'detail' in error) {
    dispatch(appActions.setError(error.message));
    dispatch(appActions.setStatusInfo(error));
  } else {
    dispatch(appActions.setError('An unknown error occurred'));
  }

  console.log(error);
  return undefined;
};
