import { Dispatch } from '@reduxjs/toolkit';
import { catchErrorUtils } from 'utils/catchErrorUtils';

import { doRequest, post } from '../../../domain/api';
import RequestError from '../../../domain/exceptions/requestError';

export const createConversations = async (dispatch: Dispatch, user: string, sessionId: string) => {
  try {
    const newChatPayload = {
      sessionId: sessionId,
    };

    return await doRequest(
      post(dispatch, `magenta_gateway/session`, {}, { ...newChatPayload }),
      (message, detail) => new RequestError('create', message, detail),
    );
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};
