export type RawUser = {
  id: string;
  createdTimestamp: number;
  username: string;
  enabled: boolean;
  totp: boolean;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  email: string;
  disableableCredentialTypes: unknown[];
  requiredActions: unknown[];
  notBefore: number;
  access: {
    manageGroupMembership: boolean;
    view: boolean;
    mapRoles: boolean;
    impersonate: boolean;
    manage: boolean;
  };
};

export type UserDataCore = {
  username: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  enabled?: boolean;
  isAdmin?: boolean;
};

export type NewUser = UserDataCore & { pwd: string };
export type ExistingUser = UserDataCore & { id: string; pwd?: string };
export type ExistingUserPassword = UserDataCore & { id: string; pwd?: string };

class Auth {
  constructor(
    public username: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public id?: string,
  ) {}
}

export default Auth;
