import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function LanguageSelection() {
  const { i18n } = useTranslation();
  const languageButtonClasses = 'w-[40px] rounded border-none';
  const handleClickEnglish = () => {
    i18n.changeLanguage('en');
  };

  const handleClickGerman = () => {
    i18n.changeLanguage('de');
  };

  return (
    <div className="flex h-[40px] w-[80px] justify-between">
      <ToggleButtonGroup exclusive aria-label="text alignment" className="text-[16px]">
        <ToggleButton
          size="small"
          value="left"
          onClick={handleClickEnglish}
          className={`${languageButtonClasses} ${i18n.language === 'en' ? 'bg-[#eaeaea] font-bold' : ''}`}
        >
          EN
        </ToggleButton>
        <ToggleButton
          size="small"
          value="right"
          onClick={handleClickGerman}
          className={`${languageButtonClasses} ${i18n.language === 'de' ? 'bg-[#eaeaea] font-bold' : ''}`}
        >
          DE
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
