import { Dispatch } from '@reduxjs/toolkit';
import { catchErrorUtils } from 'utils/catchErrorUtils';

import { del, doRequest, get, patch } from '../../../domain/api';
import RequestError from '../../../domain/exceptions/requestError';
import { ConversationInterface } from './types';

export const fetchConversations = async (dispatch: Dispatch): Promise<ConversationInterface[] | undefined> => {
  try {
    const result = await doRequest<ConversationInterface[]>(
      get(dispatch, `magenta_gateway/session`),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );

    return result;
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};

export const deleteConversation = async (
  dispatch: Dispatch,
  data: { sessionId: string },
): Promise<string | undefined> => {
  try {
    const result = await doRequest<string>(
      del(dispatch, `magenta_gateway/session`, undefined, data),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );

    return result;
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};

export const updateConversationTitle = async (
  dispatch: Dispatch,
  data: { userId: string; sessionId: string; sessionTitle: string },
): Promise<string | undefined> => {
  try {
    const result = await doRequest<string>(
      patch(dispatch, `magenta_gateway/session/title/rename`, undefined, data),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );

    return result;
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};

export const generateConversationTitle = async (
  dispatch: Dispatch,
  data: { userId: string; sessionId: string },
): Promise<string | undefined> => {
  try {
    const result = await doRequest<string>(
      patch(dispatch, `magenta_gateway/session/title/generate`, undefined, data),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );

    return result;
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};
