import { ErrorOutline } from '@mui/icons-material';
import { t } from 'i18next';
import React from 'react';

interface ErrorMessageProps {
  errorMessage: string;
  size?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMessage, size }) => {
  const fontClass = (value: string) => {
    switch (value) {
      case 'fontSize':
        return size === 'small' ? 'text-sm' : 'text-sm md:text-base lg:text-xl';

      case 'iconSize':
        return size === 'small' ? 'mb-2 text-[30px]' : 'text-[30px] sm:text-3xl md:text-4xl lg:text-5xl';

      default:
        return 'mb-2 text-[30px]';
    }
  };

  return (
    <>
      <div className="flex items-start justify-center text-center" id="errorMszMainContainer">
        <div
          className={`relative flex ${
            size === 'small' ? 'w-2/3' : 'w-1/2'
          } mt-12 flex-col items-center rounded border border-red-400 bg-red-50 px-4 py-3 text-red-700`}
          role="alert"
        >
          <ErrorOutline className={fontClass('iconSize')} />
          <div className={fontClass('fontSize')}>
            <p>{errorMessage}</p>
            <p>{t('If the problem persists please reach out to the service desk')}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorMessage;
