import { t } from 'i18next';
import { SourceFileInfo } from 'model/services/promt/types';
import React, { useState } from 'react';

import { OutputSourceModal } from './OutputSourceModal';

interface OutputSourceListProps {
  sources: SourceFileInfo[] | null;
}

const OutputSourceList: React.FC<OutputSourceListProps> = ({ sources }) => {
  const [openSourceModal, setOpenSourceModal] = useState<boolean>(false);
  const [sourceContent, setSourceContent] = useState<SourceFileInfo>();

  // Function to overcome type inference issue for the source array
  function isSourceType(source: SourceFileInfo): source is SourceFileInfo {
    return (source as SourceFileInfo).fileName !== undefined;
  }

  const handleCloseSourceModal = () => {
    setOpenSourceModal(false);
  };

  // Function to handle Source Modal popup when user clicks on any sources
  const handleSourceModel = (src: SourceFileInfo) => {
    setSourceContent(src);
    setOpenSourceModal(true);
  };

  return (
    <>
      <div className="mt-6 flex flex-col gap-1">
        {sources?.length ? <div className="px-[2px] py-1 text-[14px]">{t('Output Sources')}:</div> : ''}
        <ol className="flex flex-wrap gap-2 text-[12px]">
          {sources?.map((src, ind) => (
            <li
              key={ind}
              className="flex items-center px-1 py-1 hover:cursor-pointer hover:rounded hover:bg-grey-background"
              onClick={() => handleSourceModel(src as SourceFileInfo)}
            >
              {isSourceType(src) ? src.fileName : ''}
            </li>
          ))}
        </ol>
      </div>
      <OutputSourceModal open={openSourceModal} onClose={handleCloseSourceModal} sourceContent={sourceContent} />
    </>
  );
};

export default OutputSourceList;
