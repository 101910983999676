import BoltIcon from '@mui/icons-material/Bolt';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import CustomSnackbar from 'components/chat/CustomSnackbar';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';
import { updateRateLimitCount } from 'utils/chatUtils';
import { abbreviateNumberToCompactString } from 'utils/index';

import { RateLimitExceededModal } from './RateLimitExceededModal';

export const RateLimitComponent = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const remaining_tokens = useSelector((state: TStore) => state.rateLimitReducer.remaining_tokens);

  const dispatch = useDispatch();

  const [openRateLimitExceededDialog, setOpenRateLimitExceededDialog] = React.useState(false);

  useEffect(() => {
    const updateFunction = () => {
      updateRateLimitCount(dispatch, errorCallback);
    };

    updateFunction();

    const intervalId = setInterval(updateFunction, 10 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const errorCallback = () => {
    setSnackbarOpen(true);
    setSnackbarMessage('magenta_gateway/rate-limits/global');
  };

  const handleCloseRateLimitExceededDialog = () => {
    setOpenRateLimitExceededDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Tooltip title={remaining_tokens ? `${remaining_tokens} tokens` : t('Fetching Tokens...')}>
        <div
          className="flex cursor-pointer items-center gap-2 rounded-md text-black hover:bg-grey-background"
          onClick={() => {
            if (remaining_tokens) setOpenRateLimitExceededDialog(true);
          }}
        >
          <div className="rounded-full bg-magenta">
            <BoltIcon className="text-2xl text-white" />
          </div>
          {remaining_tokens !== null && (
            <div id="rateLimitTokenCount">
              <span className="mr-1 font-teleneo text-base font-semibold leading-[26px]">
                {abbreviateNumberToCompactString(remaining_tokens)}
              </span>
            </div>
          )}
          {remaining_tokens === null && (
            <CircularProgress size={20} className="col-span-2 justify-self-center text-magenta" />
          )}
        </div>
      </Tooltip>
      <RateLimitExceededModal open={openRateLimitExceededDialog} onClose={handleCloseRateLimitExceededDialog} />
      <CustomSnackbar open={snackbarOpen} message={snackbarMessage} onClose={handleSnackbarClose} />
    </>
  );
};
