import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { t } from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { abbreviateNumberToCompactString } from 'utils';

interface RateLimitExceededProps {
  open: boolean;
  onClose: () => void;
}

export const RateLimitExceededModal: React.FC<RateLimitExceededProps> = ({ open, onClose }) => {
  const token_allocated = useSelector((state: TStore) => state.rateLimitReducer.token_allocated);
  const contact_email = useSelector((state: TStore) => state.rateLimitReducer.contact_email);

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="rate-limit-exceeded-dialog-title"
        aria-describedby="rate-limit-exceeded-dialog-description"
        classes={{ paper: 'bg-grey-background' }}
      >
        <DialogTitle id="rate-limit-exceeded-dialog-title" className="flex justify-between">
          <span className="font-bold leading-9">{t('Token Limit')}</span>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" className="cursor-pointer" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="rate-limit-exceeded-dialog-description">
            {t('Your organisation has a limit of')}
            {token_allocated && (
              <Tooltip title={`${token_allocated} tokens`}>
                <span>
                  {' '}
                  {abbreviateNumberToCompactString(token_allocated)} {t('Tokens')}
                  {'. '}
                </span>
              </Tooltip>
            )}
            {t('If you reach the limit and would like to extend it, please contact your T-Systems contact person.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="p-6">
          <Button onClick={handleClose} variant="text" className="leading-6 text-inputtext">
            {t('Back to chat')}
          </Button>
          <a href={`mailto:${contact_email}`}>
            <Button variant="contained" className="bg-magenta leading-6">
              {t('Contact')}
            </Button>
          </a>
        </DialogActions>
      </Dialog>
    </div>
  );
};
