import { createSlice } from '@reduxjs/toolkit';
import { SessionFileInfo } from 'model/services/globalUpload/types';

interface initialInterface {
  uploadingFiles: string[];
  uploadedFiles: SessionFileInfo[];
  loading: boolean;
  isUploading: boolean;
  isFileAttachedFromNewChat: boolean;
}

const initialState: initialInterface = {
  uploadingFiles: [],
  uploadedFiles: [],
  loading: false,
  isUploading: false,
  isFileAttachedFromNewChat: false,
};

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    addFilesToUpload: (state = initialState, action) => {
      state.uploadingFiles.push(...action.payload);
    },
    removeFilesFromUploading: (state = initialState) => {
      state.uploadingFiles = [];
      state.isUploading = false;
    },
    removeUploadedFiles: (state = initialState, action) => {
      state.uploadedFiles = state.uploadedFiles.filter(fileinfo => fileinfo.hash !== action.payload);
    },
    updateUploadedFilesList: (state = initialState, action) => {
      state.uploadedFiles = action.payload;
    },
    setLoader: (state = initialState, action) => {
      state.loading = action.payload;
    },
    setUploadingState: (state = initialState, action) => {
      state.isUploading = action.payload;
    },
    setSessionFileCondition: (state = initialState, action) => {
      state.isFileAttachedFromNewChat = action.payload;
    },
    reset: () => initialState,
  },
});

export const { actions: filesAction } = filesSlice;
export const filesReducer = filesSlice.reducer;
