import { Divider, Skeleton } from '@mui/material';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ErrorMessage from 'components/chat/ErrorMessage';
import { PrevChat } from 'components/PrevChat';
import { t } from 'i18next';
import { fetchConversations } from 'model/services/conversations/fetchConversations';
import { ConversationInterface } from 'model/services/conversations/types';
import { conversationsActions } from 'model/slices/conversationsSlice';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';

interface PrevChatProps {}

enum ConversationCategory {
  TODAY = 'TODAY',
  PREVIOUS_30_DAYS = 'PREVIOUS 30 DAYS',
  EARLIER = 'EARLIER',
}

interface categorizedConversationsInterface {
  [key: string]: ConversationInterface[];
}

export const PrevChats: React.FC<PrevChatProps> = () => {
  const [categorizedConversations, setCategorizedConversations] = React.useState<categorizedConversationsInterface>({
    [ConversationCategory.TODAY]: [],
    [ConversationCategory.PREVIOUS_30_DAYS]: [],
    [ConversationCategory.EARLIER]: [],
  });
  let conversations: ConversationInterface[] = [];
  const conversationsData = useSelector((store: TStore) => store.conversationsReducer.conversations);
  const [loadingConversations, setLoadingConversations] = React.useState(true);
  const [errorInloading, setErrorInloading] = React.useState(false);
  const dispatch = useDispatch();

  function sortConversationsByDate() {
    if (conversationsData && Array.isArray(conversationsData)) {
      conversations = [...conversationsData];
    } else {
      console.log('Conversations could not be loaded from conversations Reducer');
      return;
    }
    conversations.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    categorizedConversations[ConversationCategory.TODAY] = [];
    categorizedConversations[ConversationCategory.PREVIOUS_30_DAYS] = [];
    categorizedConversations[ConversationCategory.EARLIER] = [];
    conversations.forEach(conversation => {
      const conversation_date = new Date(conversation.updatedAt);
      const currentDate = new Date();
      const dateDiff = currentDate.getTime() - conversation_date.getTime();
      if (dateDiff < 24 * 60 * 60 * 1000) {
        categorizedConversations[ConversationCategory.TODAY].push(conversation);
      } else if (dateDiff <= 30 * 24 * 60 * 60 * 1000) {
        categorizedConversations[ConversationCategory.PREVIOUS_30_DAYS].push(conversation);
      } else {
        categorizedConversations[ConversationCategory.EARLIER].push(conversation);
      }
    });

    setCategorizedConversations({ ...categorizedConversations });
  }

  React.useEffect(() => {
    async function updateConversations() {
      setLoadingConversations(true);
      const user = localStorage.getItem('user');
      try {
        if (user) {
          const conversations = await fetchConversations(dispatch);
          dispatch(conversationsActions.updateConversations(conversations));
          if (!conversations) {
            setErrorInloading(true);
          }
        }
      } catch (error) {
        setErrorInloading(true);
        console.log('Error in fetching conversations !', error);
      } finally {
        setLoadingConversations(false);
      }
    }

    updateConversations();
  }, []);

  React.useEffect(() => {
    sortConversationsByDate();
  }, [conversationsData]);

  return (
    <>
      {!errorInloading && (
        <List
          dense={true}
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: '100%',
            '& ul': { padding: 0 },
          }}
          subheader={<li className="list-none" />}
        >
          {!loadingConversations &&
            Object.entries(categorizedConversations).map(([key, value], index) => (
              <div key={`div-${key}`}>
                {value.length > 0 && (
                  <li key={`section-${key}`}>
                    <ul>
                      <ListSubheader className="mx-2 pb-1 pt-4 leading-none">{t(`${key}`)}</ListSubheader>
                      {value.map((conversation, idx) => (
                        <PrevChat
                          chatDivId={`prevChatNum_${idx}`}
                          key={`item-${idx}-${conversation.sessionId}`}
                          conversation={conversation}
                        />
                      ))}
                    </ul>
                    <Divider className={`mx-7 border-grey-background ${index === 2 ? 'hidden' : ''}`} />
                  </li>
                )}
              </div>
            ))}
          {loadingConversations && (
            <>
              {[...Array(2)].map((_, index1) => (
                <div key={`skeleton-${index1}`}>
                  <Skeleton variant="text" className="ml-4 mr-10 text-base" />
                  {[...Array(3)].map((_, index) => (
                    <Skeleton key={`skeleton-${index}`} className="mx-4 h-16"></Skeleton>
                  ))}
                </div>
              ))}
            </>
          )}
        </List>
      )}

      {errorInloading && (
        <ErrorMessage size="small" errorMessage={t('Loading the content failed because of an unknown error')} />
      )}
    </>
  );
};
