import { Button } from '@mui/material';
import { t } from 'i18next';
import React, { PropsWithChildren } from 'react';

interface SidebarFooterButtonProps {
  title: string;
  iconPath?: string;
  Icon?: JSX.Element;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showBubble?: boolean;
}

export const SidebarFooterButton: React.FC<PropsWithChildren<SidebarFooterButtonProps>> = ({
  title,
  handleClick,
  Icon,
}) => {
  const userMenuContainerRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (userMenuContainerRef.current && !userMenuContainerRef.current.contains(event.target as Node)) {
        console.log('click event in container');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mx-2 flex cursor-pointer items-center justify-center rounded-md font-teleneo">
      <Button
        variant="text"
        size="large"
        className="w-full bg-white px-4 py-1.5 text-sm capitalize text-inputtext hover:bg-grey-background"
        startIcon={Icon}
        onClick={handleClick}
      >
        <span className="flex-grow text-justify">{t(title)}</span>
      </Button>
    </div>
  );
};

export default SidebarFooterButton;
