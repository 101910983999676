import DOMPurify from 'dompurify';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import FailedPromptResponse from './FailedPromptResponse';

interface Props {
  message: string;
  errMsg?: string;
}

const SanitizedMessageComponent: React.FC<Props> = ({ message, errMsg }) => {
  // Sanitize input message contents
  const sanitizedMessage = DOMPurify.sanitize(message, {
    ALLOWED_TAGS: [
      'p',
      'strong',
      'em',
      'del',
      'code',
      'pre',
      'table',
      'thead',
      'tbody',
      'tr',
      'th',
      'td',
      'br',
      'hr',
      'ul',
      'ol',
      'li',
      'a',
      'img',
    ],
    ALLOWED_ATTR: ['href', 'src', 'alt', 'title'],
  });

  return (
    <div className="markdown-content">
      {sanitizedMessage === 'undefined' ? (
        <FailedPromptResponse errMsg={errMsg} />
      ) : (
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{sanitizedMessage.replace(/\\r\\n/g, '\n')}</ReactMarkdown>
      )}
    </div>
  );
};

export default SanitizedMessageComponent;
