import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomSnackbar from 'components/chat/CustomSnackbar';
import { t } from 'i18next';
import { fetchAppVersion } from 'model/services/appVersion/fetchAppVersion';
import { VersionInfo } from 'model/services/appVersion/types';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface AppVersionModelProps {
  open: boolean;
  onClose: () => void;
}

interface CustomTooltipProps {
  title: string;
  children: ReactNode;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, children }) => {
  return (
    <Tooltip
      title={title || ''}
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <div className="overflow-hidden text-ellipsis whitespace-nowrap">{children}</div>
    </Tooltip>
  );
};

export const AppVersionModel: React.FC<AppVersionModelProps> = ({ open, onClose }) => {
  const [currAppVersion, setCurrAppVersion] = useState<VersionInfo | undefined>({
    gateway_version: '',
    gateway_build_time: '',
    rag_version: '',
    rag_build_time: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const getVersionDetails = async () => {
      try {
        const versionDetails = await fetchAppVersion(dispatch);
        setCurrAppVersion(versionDetails);
        if (!versionDetails) {
          setSnackbarOpen(true);
          setSnackbarMessage('magenta_gateway/version');
        }
      } catch (error) {
        console.log('Error in fetching the application version details !', error);
        setSnackbarOpen(true);
        setSnackbarMessage('magenta_gateway/version');
      }
    };

    getVersionDetails();
  }, []);

  const handleAppModalClose = () => {
    onClose();
  };

  const formatInputDate = (dateStr: string | undefined): string => {
    if (!dateStr) {
      return 'Buildzeitpunkt unbekannt.';
    }

    return `${dateStr.replace(' ', ', ')} Uhr`;
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleAppModalClose}
          aria-labelledby="app-version-dialog-title"
          aria-describedby="app-version-dialog-description"
          classes={{ paper: 'bg-grey-background' }}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="app-version-dialog-title"
            className="flex justify-between gap-4 px-6 py-2 md:py-3 md:text-xl"
          >
            <span className="font-bold leading-9">{t('Version Information')}</span>
            <IconButton onClick={handleAppModalClose} className="bg-grey-background p-0">
              <CloseIcon fontSize="small" className="cursor-pointer" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="app-version-dialog-description" className="text-sm md:text-base">
              {currAppVersion ? (
                <div className="grid grid-cols-10 gap-4 text-sm md:gap-8">
                  <div className="col-span-3 flex flex-col gap-2">
                    <div>{t('Smart Health Chat Frontend')}:</div>
                    <div>{t('Magenta Smartgateway')}:</div>
                    <div>{t('Knowledge Base Search')}:</div>
                  </div>
                  <div className="col-span-4 flex flex-col gap-2">
                    <CustomTooltip title={process.env.REACT_APP_VERSION ?? t('Version nicht verfügbar')}>
                      {process.env.REACT_APP_VERSION ?? t('Version nicht verfügbar')}
                    </CustomTooltip>
                    <CustomTooltip title={currAppVersion.gateway_version ?? t('Version nicht verfügbar')}>
                      {currAppVersion.gateway_version ?? t('Version nicht verfügbar')}
                    </CustomTooltip>
                    <CustomTooltip title={currAppVersion.rag_version ?? t('Version nicht verfügbar')}>
                      {currAppVersion.rag_version ?? t('Version nicht verfügbar')}
                    </CustomTooltip>
                  </div>
                  <div className="col-span-3 flex flex-col gap-2">
                    <div>{formatInputDate(process.env.REACT_APP_BUILD_TIME)}</div>
                    <div>{formatInputDate(currAppVersion.gateway_build_time)}</div>
                    <div>{formatInputDate(currAppVersion.rag_build_time)}</div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-4">
                  <div className="flex gap-4 md:gap-8">
                    <div>{t('Smart Health Chat Frontend')}:</div>
                    <div className="w-36">
                      <CustomTooltip title={process.env.REACT_APP_VERSION ?? ''}>
                        {process.env.REACT_APP_VERSION ?? ''}
                      </CustomTooltip>
                    </div>
                    <div>{process.env.REACT_APP_BUILD_TIME}</div>
                  </div>

                  {/* <CircularProgress size={30} className="text-magenta" /> */}
                  <Alert severity="error">{t('Error fetching version')}</Alert>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      <CustomSnackbar open={snackbarOpen} message={snackbarMessage} onClose={handleSnackbarClose} />
    </>
  );
};
