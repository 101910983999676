import { Dispatch } from '@reduxjs/toolkit';

import { doRequest, get } from '../../../domain/api';
import RequestError from '../../../domain/exceptions/requestError';
import { appActions } from '../../slices/appSlice';

export const fetchSelectedConversation = async (dispatch: Dispatch, user: string, sessionId: string | undefined) => {
  try {
    return await doRequest(
      get(dispatch, `magenta_gateway/session/${sessionId}`),
      (message, detail) => new RequestError('create', message, detail),
    );
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'message' in error) {
      dispatch(appActions.setError(error.message));
    } else {
      dispatch(appActions.setError('An unknown error occurred'));
    }

    console.log(error);
    return undefined;
  }
};
