import { createSlice } from '@reduxjs/toolkit';
import Auth, { ExistingUser } from 'domain/auth';

interface initialInterface {
  _inited: boolean;

  user: ExistingUser | null;
  auth: Auth | null;

  loading: boolean;
  error: string;
  success: string;
  statusCode: number | undefined | null;
  statusDetail: string;

  isDrawerOpen: boolean;
  showDefaultContent: boolean;
  showChatScreen: boolean;
}

const initialState: initialInterface = {
  _inited: false,

  user: null,
  auth: null,

  loading: false,
  error: '',
  success: '',
  isDrawerOpen: true,
  showDefaultContent: true,
  showChatScreen: false,
  statusCode: null,
  statusDetail: '',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateApp: (state = initialState, action) => ({
      ...state,
      ...action.payload,
    }),
    setLoading: (state = initialState, action) => {
      state.loading = action.payload;
    },
    setSuccess: (state = initialState, action) => {
      state.success = action.payload;
    },
    setError: (state = initialState, action) => {
      state.error = action.payload;
    },
    setStatusInfo: (state = initialState, action) => {
      state.statusCode = action.payload.statusCode;
      state.statusDetail = action.payload.statusDetail;
    },
    setAuthData: (state = initialState, action) => {
      state.auth = action.payload;
    },
    setUser: (state = initialState, action) => {
      state.user = action.payload;
    },
    initAuthData: (state = initialState) => {
      const user = localStorage.getItem('user');
      if (user) {
        state.auth = JSON.parse(user);
      }
      state._inited = true;
    },
    logout: (state = initialState) => {
      state.auth = null;
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('refreshToken');
      sessionStorage.removeItem('roles');
    },
    setDrawerState: (state = initialState, action) => {
      state.isDrawerOpen = action?.payload;
    },
    setShowDefaultContent: (state = initialState, action) => {
      state.showDefaultContent = action.payload;
    },
    setShowChatScreen: (state = initialState, action) => {
      state.showChatScreen = action.payload;
    },
    reset: (state = initialState) => {
      state.isDrawerOpen = true;
      state.showDefaultContent = true;
      state.showChatScreen = false;
    },
  },
});

export const { actions: appActions } = appSlice;
export const appReducer = appSlice.reducer;
