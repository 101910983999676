import { createSlice } from '@reduxjs/toolkit';
import { ConversationHistoryInterface, ConversationInterface } from 'model/services/conversations/types';
import { IMessage } from 'model/services/promt/types';

interface initialInterface {
  conversations: ConversationInterface[];
  activeConversation: ConversationInterface | null;
  activeConversationHistory: ConversationHistoryInterface | null;
  currentConversationChat: IMessage[];
  isPastConversation: boolean;
  promptResponse: string;
  displayedPromptResponse: string;
  conversationLoadingError: boolean;
  conversationLoading: boolean;
  stopConversationSignal: boolean;
}

const initialState: initialInterface = {
  conversations: [],
  activeConversation: null,
  activeConversationHistory: null,
  currentConversationChat: [],
  isPastConversation: false,
  promptResponse: '',
  displayedPromptResponse: '',
  conversationLoadingError: false,
  conversationLoading: false,
  stopConversationSignal: false,
};

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    updateConversations: (state = initialState, action) => {
      if (action.payload) {
        state.conversations = action.payload;
      }
    },
    removeConversation: (state = initialState, action) => {
      if (action.payload) {
        state.conversations = state.conversations.filter(conversation => conversation.sessionId !== action.payload);
      }
    },
    updateConversationTitle: (state = initialState, action) => {
      if (action.payload) {
        const { sessionId, sessionTitle } = action.payload;
        const conversation = state.conversations.find(conv => conv.sessionId === sessionId);
        if (conversation) {
          conversation.sessionTitle = sessionTitle;
        }
      }
    },
    setActiveConversation: (state = initialState, action) => {
      state.activeConversation = action.payload;
    },
    setCurrentConversationChat: (state = initialState, action) => {
      state.currentConversationChat = action.payload;
    },
    setActivatePastConversationType: (state = initialState, action) => {
      state.isPastConversation = action.payload;
    },
    setPromptResponse: (state = initialState, action) => {
      state.promptResponse = state.promptResponse + action.payload;
    },
    overwritePromptResponse: (state = initialState, action) => {
      state.promptResponse = action.payload;
    },
    clearPromptResponse: (state = initialState) => {
      state.promptResponse = '';
    },
    setDisplayedPromptResponse: (state = initialState, action) => {
      state.displayedPromptResponse = action.payload;
    },
    setConversationLoading: (state = initialState, action) => {
      state.conversationLoading = action.payload;
    },
    setConversationLoadingError: (state = initialState, action) => {
      state.conversationLoadingError = action.payload;
    },
    setConversationSignal: (state = initialState, action) => {
      state.stopConversationSignal = action.payload;
    },
    reset: () => initialState,
  },
});

export const { actions: conversationsActions } = conversationsSlice;
export const conversationsReducer = conversationsSlice.reducer;
