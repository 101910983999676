import { Tooltip } from '@mui/material';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

interface CustomTooltipProps {
  title: string;
  children: ReactNode;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, children }) => {
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (titleRef.current) {
      setIsTextTruncated(titleRef.current.scrollWidth > titleRef.current.clientWidth);
    }
  }, [title]);

  return (
    <Tooltip
      title={isTextTruncated ? title : ''}
      arrow
      enterDelay={300}
      leaveDelay={100}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <div ref={titleRef} id="customTooltipMainContainer" className="truncate">
        {children}
      </div>
    </Tooltip>
  );
};
