import { createSlice } from '@reduxjs/toolkit';

interface initialInterface {
  isClickedFromResetPassword: boolean;
}

const initialState: initialInterface = {
  isClickedFromResetPassword: false,
};

const dataPrivacySlice = createSlice({
  name: 'dataPrivacySlice',
  initialState,
  reducers: {
    setClickedForRouting: (state = initialState, actions) => {
      state.isClickedFromResetPassword = actions.payload;
    },
  },
});

export const { setClickedForRouting } = dataPrivacySlice.actions;
export const dataPrivacyReducer = dataPrivacySlice.reducer;
