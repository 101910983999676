import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { t } from 'i18next';
import LanguageSelection from 'layout/header/LanguageSelection';
import { cookieConsentActions } from 'model/slices/cookiesSlice';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { footerRoutes } from 'root/entities/routes';
import { TStore } from 'store';

interface CookiesNoticeDialogProps {}

export const CookiesNoticeDialog: React.FC<CookiesNoticeDialogProps> = () => {
  const [, setCookie] = useCookies(['_Secure-cookieConsent', '_Secure-cookieConsentTimestamp']);
  const openCookieBanner = useSelector((state: TStore) => state.cookieConsentReducer.openCookieBanner);
  const dispatch = useDispatch();

  const handleDecline = () => {
    dispatch(cookieConsentActions.openCookieDialog(false));
    dispatch(cookieConsentActions.acceptCookieConsent(false));
    dispatch(cookieConsentActions.displayAcceptCookieContent(false));
  };

  const handleCookieAccept = () => {
    setCookie('_Secure-cookieConsent', 'accepted', { path: '/', secure: true });
    setCookie('_Secure-cookieConsentTimestamp', new Date().toISOString(), {
      path: '/magenta_gateway/',
      secure: true,
    });
    dispatch(cookieConsentActions.acceptCookieConsent(true));
    dispatch(cookieConsentActions.openCookieDialog(false));
    dispatch(cookieConsentActions.displayAcceptCookieContent(true));
  };

  const handleDialogClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<string, string>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  return (
    <div id="test_cookie_notice">
      <Dialog
        open={openCookieBanner}
        onClose={handleDialogClose}
        classes={{
          paper:
            'bg-white shadow-inputBox w-full max-h-[506px] md:h-[482px] sm:h-[506px] top-0 fixed px-8 pt-5 pb-8 flex items-center justify-center',
          root: openCookieBanner ? '' : 'hidden',
        }}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        transitionDuration={700}
        fullScreen
      >
        <div className="flex h-[450px] w-[500px] flex-col gap-8 sm:h-[426px] sm:w-[592px] md:h-[402px] md:w-[720px]">
          <DialogTitle id="prompt-templates-available-dialog-title" className="flex h-[40px] justify-between p-0">
            <span className="text-[24px] font-bold leading-8">{t('Consent_data_processing')}</span>
            <LanguageSelection />
          </DialogTitle>
          <DialogContent className="h-[256px] p-0">
            <DialogContentText className="flex flex-col gap-2 tracking-[0.15px] text-[#202020] sm:text-[14px] md:text-[15px] lg:text-[16px]">
              <div className="flex flex-col gap-2">
                <div>{t('data_processing_consent_1')}</div>
                <div>{t('data_processing_consent_2')}</div>
              </div>

              {/* Data privacy link here. */}
              <div>
                {footerRoutes.map(item => {
                  return (
                    <Link
                      className="text-base text-magenta underline"
                      key={item.href}
                      to={item.href}
                      data-testid="dataPrivacyLink_cookie_link"
                    >
                      {t('data_privacy_info_link')}
                    </Link>
                  );
                })}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="flex h-[42px] justify-between gap-4 p-0">
            <Button
              onClick={handleCookieAccept}
              variant="text"
              id="accept_cookie_btn"
              className="h-full w-1/2 bg-magenta px-[22px] py-[8px] text-[15px] font-normal normal-case leading-3 text-white hover:bg-darkmagenta md:text-sm lg:text-base lg:leading-6"
            >
              {t('accept_cookie')}
            </Button>
            <Button
              onClick={handleDecline}
              variant="outlined"
              className="h-full w-1/2 border-[#7C7C7C] bg-white px-[22px] py-[8px] text-[15px] font-semibold normal-case leading-3 text-[#7C7C7C] hover:bg-grey-background md:text-sm lg:text-base lg:leading-6"
            >
              {t('decline')}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
