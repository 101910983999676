export type RequestErrorType = 'create' | 'update' | 'delete' | 'read';
class RequestError extends Error {
  constructor(
    public action: RequestErrorType,
    public message: string,
    public detail: unknown,
    public statusCode?: number,
  ) {
    super();
  }
}

export default RequestError;
