import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TextField } from '@mui/material';
import CustomSnackbar from 'components/chat/CustomSnackbar';
import useWindowDimensions from 'hooks/WindowDimensions';
import { t } from 'i18next';
import { deleteConversation, updateConversationTitle } from 'model/services/conversations/fetchConversations';
import { fetchModelSelection } from 'model/services/conversations/fetchModelSelection';
import { fetchSelectedConversation } from 'model/services/conversations/fetchSelectedConversation';
import { ConversationInterface, ModelSelectionInterface } from 'model/services/conversations/types';
import { fetchSessionFilesList } from 'model/services/files/fetchFiles';
import { IMessage, SourceFileInfo } from 'model/services/promt/types';
import { closeAIPromptScreen } from 'model/slices/aiPromptLib';
import { appActions } from 'model/slices/appSlice';
import { conversationsActions } from 'model/slices/conversationsSlice';
import { modelSelectionActions } from 'model/slices/modelSelectionSlice';
import { outputStyleActions } from 'model/slices/outputStyleSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';
import { onNewChatScreenClick as navigateToNewChatScreen } from 'utils/chatUtils';

import { filesAction } from '../../model/slices/filesSlice';
import { globalUploadActions } from '../../model/slices/globalUpload';

interface PrevChatProps {
  conversation: ConversationInterface;
  chatDivId: string;
}

interface ConversationHistoryItem {
  prompt: string;
  response: string;
  sources: SourceFileInfo[];
}

export const PrevChat: React.FC<PrevChatProps> = ({ conversation, chatDivId }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { activeConversation } = useSelector((store: TStore) => store.conversationsReducer);
  const [editedChatHeading, setEditedChatHeading] = useState(conversation.sessionTitle);
  const dispatch = useDispatch();
  const { windowInnerWidth } = useWindowDimensions();
  const selectedModel = useSelector((state: TStore) => state.modelSelectionReducer.selectedModel);
  const showDefaultContent = useSelector((state: TStore) => state.appReducer.showDefaultContent);

  useEffect(() => {
    if (conversation.sessionId !== conversation.sessionTitle) {
      setEditedChatHeading(conversation?.sessionTitle);
    }
  }, [conversation]);

  const handleEditClick = (event: React.MouseEvent): void => {
    event.stopPropagation();
    if (conversation.sessionId === conversation.sessionTitle) {
      setEditedChatHeading(t('New Chat'));
    }
    setIsEditing(!isEditing);
  };

  const handleCloseClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setEditedChatHeading(conversation.sessionTitle);
    setIsEditing(false);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleChatHeadingChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEditedChatHeading(event.target.value);
  };

  const handleDeleteConversationClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const user = localStorage.getItem('user');
    if (user) {
      try {
        const response = await deleteConversation(dispatch, {
          sessionId: conversation.sessionId,
        });
        if (response) {
          dispatch(conversationsActions.removeConversation(conversation.sessionId));
          navigateToNewChatScreen(dispatch, windowInnerWidth, showDefaultContent, selectedModel);
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage('magenta_gateway/session');
        }
      } catch (error) {
        console.log('Error in deleting conversation !', error);
        setSnackbarOpen(true);
        setSnackbarMessage('magenta_gateway/session');
      }
    }
  };

  const parseSelectedConversation = (conversationHistory: ConversationHistoryItem[]) => {
    try {
      const requiredConversationFormat: IMessage[] = conversationHistory.reduce(
        (acc: IMessage[], curr: ConversationHistoryItem, index: number) => {
          acc.push({
            id: index * 2,
            text: curr.prompt,
            isUserMessage: true,
            source: null,
          });
          acc.push({
            id: index * 2 + 1,
            text: curr.response,
            isUserMessage: false,
            source: curr.sources,
            isSourceAvailable: curr.sources ? true : false,
          });
          return acc;
        },
        [] as IMessage[],
      );
      return requiredConversationFormat;
    } catch (error) {
      console.log('Parsing error due to illegal JSON format: ', error);
    }
  };

  // handleSelectChatClick - responsible for all the actions when user clicks any of the past chats in side panel
  const handleSelectChatClick = async () => {
    dispatch(conversationsActions.setConversationLoading(true));
    dispatch(conversationsActions.setConversationLoadingError(false));
    dispatch(appActions.setShowDefaultContent(false));
    dispatch(globalUploadActions.closeGlobalUpload());
    dispatch(appActions.setShowChatScreen(true));
    dispatch(filesAction.setSessionFileCondition(false));
    const user = localStorage.getItem('user');
    const user_id = JSON.parse(user as string).username;
    dispatch(conversationsActions.setActivatePastConversationType(true));
    dispatch(conversationsActions.setActiveConversation({ ...conversation }));
    if (windowInnerWidth < 640) {
      dispatch(appActions.setDrawerState(false));
    }
    const result = await fetchSelectedConversation(dispatch, user_id, conversation.sessionId);
    const selectedSessionParams = await fetchModelSelection(dispatch, user_id, conversation.sessionId);
    if (!selectedSessionParams) {
      setSnackbarOpen(true);
      setSnackbarMessage('magenta_gateway/session/config');
      dispatch(conversationsActions.setConversationLoading(false));
      dispatch(conversationsActions.setConversationLoadingError(true));
      return;
    }
    dispatch(filesAction.reset());
    const [selectedChatModel, selectedOutputStyle] = selectedSessionParams as ModelSelectionInterface[];
    const sessionFiles = await fetchSessionFilesList(dispatch, conversation.sessionId);
    const parsedConversartion = parseSelectedConversation(result as ConversationHistoryItem[]);
    dispatch(modelSelectionActions.setModelSelection(selectedChatModel));
    dispatch(conversationsActions.setCurrentConversationChat(parsedConversartion));
    dispatch(filesAction.updateUploadedFilesList(sessionFiles));
    dispatch(closeAIPromptScreen());
    dispatch(outputStyleActions.setTemperature(selectedOutputStyle));
    dispatch(conversationsActions.setConversationLoading(false));
  };

  const handleUpdateTitle = async (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
    const user = localStorage.getItem('user');
    try {
      if (user) {
        const parsedUser = JSON.parse(user);
        const sessionDetails = {
          sessionId: conversation.sessionId,
          userId: parsedUser?.username,
          sessionTitle: editedChatHeading.replace(/\\r\\n/g, '\n'),
        };
        const response = await updateConversationTitle(dispatch, sessionDetails);
        if (response) {
          dispatch(conversationsActions.updateConversationTitle(sessionDetails));
          setEditedChatHeading(editedChatHeading.replace(/\\r\\n/g, '\n'));
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage('magenta_gateway/session/title/rename');
        }
      }
    } catch (error) {
      console.log('Error in updating conversation title !', error);
      setSnackbarOpen(true);
      setSnackbarMessage('magenta_gateway/session/title/rename');
    }
    setIsEditing(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const activeConversationClass = activeConversation?.sessionId == conversation.sessionId ? 'bg-gray-200' : '';

  const containerClasses = `mx-2 mt-2 flex justify-between h-10 cursor-pointer items-center gap-2 rounded-md px-4 hover:bg-grey-background ${
    isHovering ? 'bg-gray-200' : ''
  } ${activeConversationClass}`;

  const chatTitle = isEditing
    ? editedChatHeading
    : conversation.sessionTitle === conversation.sessionId
    ? t('New Chat')
    : conversation.sessionTitle;

  const chatTitleComponent = isEditing ? (
    <div className="flex w-[95%] items-center space-x-2 text-ellipsis whitespace-nowrap">
      <TextField
        value={editedChatHeading}
        onChange={handleChatHeadingChange}
        autoFocus
        fullWidth={false}
        autoComplete="off"
        variant="standard"
        style={{ flex: 1 }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleUpdateTitle(e);
          }
        }}
      />
      <IconButton size="small" onClick={handleUpdateTitle}>
        <CheckIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" onClick={handleCloseClick}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  ) : (
    <div className="flex w-full items-center space-x-2 text-ellipsis whitespace-nowrap">
      <ChatBubbleOutlineIcon fontSize="small" />
      <span className="w-[95%] overflow-hidden text-ellipsis whitespace-nowrap text-sm">{chatTitle}</span>
      {isHovering && (
        <>
          <IconButton size="small" onClick={handleEditClick}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={handleDeleteConversationClick}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </>
      )}
    </div>
  );

  return (
    <div
      className={containerClasses}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleSelectChatClick}
      id={chatDivId}
    >
      {chatTitleComponent}
      <CustomSnackbar open={snackbarOpen} message={snackbarMessage} onClose={handleSnackbarClose} />
    </div>
  );
};
