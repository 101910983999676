import { createSlice } from '@reduxjs/toolkit';

interface initialInterface {
  isModelSelectionDisabled: boolean;
  selectedModel: string;
}

const initialState: initialInterface = {
  isModelSelectionDisabled: false,
  selectedModel: 'Llama 3',
};

const modelSelectionSlice = createSlice({
  name: 'modelSelection',
  initialState,
  reducers: {
    modelSelectionDisable: (state = initialState) => {
      state.isModelSelectionDisabled = true;
    },
    modelSelectionEnable: (state = initialState) => {
      state.isModelSelectionDisabled = false;
    },
    setModelSelection: (state = initialState, action) => {
      state.selectedModel = action.payload;
    },
    reset: () => initialState,
  },
});

export const { actions: modelSelectionActions } = modelSelectionSlice;
export const modelSelectionReducer = modelSelectionSlice.reducer;
