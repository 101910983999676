import { Dispatch } from '@reduxjs/toolkit';
import { catchErrorUtils } from 'utils/catchErrorUtils';

import { doRequest, get, post } from '../../../domain/api';
import RequestError from '../../../domain/exceptions/requestError';
import { ConfigPayload, UserSettingResponse } from './types';

export const updateGlobalContextSetting = async (dispatch: Dispatch, configPayload: ConfigPayload) => {
  try {
    const payload = {
      configPayload,
    };

    return await doRequest(
      post(dispatch, `magenta_gateway/user/settings`, {}, { ...payload.configPayload }),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );
  } catch (error) {
    catchErrorUtils(dispatch, error);
  }
};

export const fetchGlobalContextSetting = async (dispatch: Dispatch): Promise<UserSettingResponse | null> => {
  try {
    const result = await doRequest(
      get(dispatch, `magenta_gateway/user/settings`),
      (message, detail, statusCode) => new RequestError('create', message, detail, statusCode),
    );

    return result as UserSettingResponse;
  } catch (error) {
    catchErrorUtils(dispatch, error);
    return null;
  }
};
